

/*===========================
		1. COMMON css 
===========================*/


@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto+Condensed:300,300i,400,400i,700,700i&display=swap');

 body {
	font-family: $bar;
	font-weight: normal;
	font-style: normal;
		color: $body-text;
		overflow-x: hidden;
		&.dark-bg{
			background: #0e0e0e;
		}
		
}

*{
		margin: 0;
		padding: 0;
		@include box-sizing (border-box);
}

img {
	max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	text-decoration: none;
}

i,
span,
a{
		display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $bar;
	font-weight: 700;
	color: $heading-color;
	margin: 0px;
}

h1 {
	font-size: 48px;
}
h2 {
	font-size: 36px;
	.new-home &{
		@include font(30px,35px);
		color: $black-2;
		margin-bottom: 25px;
		@include media('>=tablet') {
			@include font(42px,56px);
			margin-bottom: 50px;
		}
		a{
			color: $black-2;
			&:hover{
				color: $theme-orange;
			}
		}
		br{
			display: none;
			@include media('>=desktop') {
				display: block;
			}
		}
	}
}
h3 {
	font-size: 28px;
	.new-home &{
		@include font(20px,25px);
		font-weight: normal;
		margin-bottom: 15px;
		@include media('>=tablet') {
			@include font(25px,30px);
		}
		@include media('>=widescreen') {
			@include font(40px,45px);
			margin-bottom: 28px;
		}
		a{
			color: $white;
			display: block;
		}
	}
}
h4 {
	font-size: 22px;
	.new-home &{
		@include font(20px,24px);
		color: $theme-orange;
		margin-bottom: 20px;
		font-weight: normal;
		@include media('>=tablet') {
			@include font(25px,30px);
			margin-bottom: 61px;
		}
	}
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul,ol {
	margin: 0px;
	padding: 0px;
		list-style-type: none;
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	color: $body-text;
	margin: 0px;
	font-family: $rob;
	.new-home &{
		color: $black-2;
		line-height: 21px;
	}
}

.bg_cover{
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
}

// others common css here

#wrapper{
	overflow: hidden;
	position: relative;
}

/*===== All Button Style =====*/

.main-btn {
	display: inline-block;
	font-weight: 700;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	@include user-select(none);
	border: 1px solid $black;
	padding: 0 68px;
	font-size: 18px;
	line-height: 60px;
	border-radius: 0px;
		color: $black;
		cursor: pointer;
		z-index: 5;
		@include transition(0.4s);
		background-color: transparent;
		text-transform: uppercase;
		
		&:hover{
				background-color: transparent;
				color: $theme-color;
				border-color: $theme-color;
		}
		
		&.main-btn-2{
				background-color: $white;
				color: $theme-color;
				border-color: $theme-color;
				&:hover{
						background-color: $theme-color;
						border-color: $theme-color;
						color: $white;
				}
		}
}



/*===== section title Style =====*/

.section-title{
	& .title{
		font-size: 30px;
		text-transform: uppercase;
		color: $black;
		border-bottom: 1px solid #c9c9c9;
		padding-bottom: 5px;
		position: relative;
		&::before{
			position: absolute;
			content: '';
			left: 50%;
			bottom: -3px;
			height: 5px;
			width: 70px;
			background: $theme-color;
			transform: translateX(-50%);

		}
		&::after{
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 12px solid $theme-color;
			bottom: -12px;
			left: 50%;
			transform: translateX(-50%);

		}
	}
}

/*===== styles for home 5=====*/
.new-home{

	hr{
		margin: 35px;
		border-top: 1px solid #707070;
		@include media('>=desktop') {
			margin: 45px;
		}
	}

	.sub-title{
		color: $theme-orange;
		display: block;
		margin-bottom: 4px;
		text-transform: uppercase;
	}
	/*===== slick arrows styles =====*/
	.slick-slide{
		outline: none;
	}
	.slick-arrow{
		@include size(51px);
		background: $theme-orange;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $black-2;
		position: absolute;
		bottom: 74px;
		z-index: 9;
		right: 15px;

		&.prev{
			background: $white;
			right: 66px;
		}
	}
	.btn-arrow{
		@extend .slick-arrow;
		position: static;
	}
	.btn-orange{
		background: $theme-orange;
		border:none;
		display: block;
		max-width: 119px;
		padding: 13px;
		text-align: center;
		width: 100%;
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		@include media('>=tablet') {
			max-width: 185px;
		}
		&:hover{
			background: lighten($theme-orange,10%);
		}
		&.radius{
			@include font(16px,20px);
			border-radius: 3px;
			padding: 10px;
			letter-spacing: 0.16px;
			@include media('>=widescreen') {
				@include font(20px,24px);
				padding: 16px;
			}
		}
		i{
			margin-left: 10px;
			@include media('>=tablet') {
				margin-left: 22px;
			}
			
		}
	}

	.user-links{
		display: flex;
		align-items: center;
		letter-spacing: 1.31px;
		margin-bottom: 18px;
		margin-top: 20px;
		li{
			margin-right: 28px;
		}
		a{
			color: $theme-orange;
			display: flex;
			align-items: center;
			&:hover{
				color: lighten($theme-orange,10%);
			}
		}
		i{
			font-size: 12px;
			margin-right: 10px;
		}
	}
}

/* ellipsis style */
.ellip {
	display: block;
	height: 100%;
}

.ellip-line {
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
}

.ellip,
.ellip-line {
	position: relative;
	overflow: hidden;
	max-width: 100%;
}



/* lightbox styles */

.popup-holder {
    position: relative;
    overflow: hidden;
    height: 0;
}

.lightbox-demo {
    padding: 5px;
    width: 300px;
}

.lightbox-demo h2 {
    margin: 0 0 10px;
}

.lightbox-demo p {
    margin: 0;
}