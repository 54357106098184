


/*===========================
    5.FEATURED NEWS css 
===========================*/



.featured-news-area{
	& .featured-news-thumb{
		position: relative;
		& img{
			width: 100%;
		}

		&.item-2{
			& .featured-news-item{
				left: 20px;
				& .item{
					padding-left: 18px;
					padding-right: 0;
				}
			}
		}
	}
	& .featured-news-content{
		padding-left: 55px;
		@media #{$lg} {
			padding-left: 0;
		}
		@media #{$md} {
			padding-left: 0;
		}
		@media #{$xs} {
			padding-left: 0;
		}

		& .title{
			font-size: 40px;
			font-weight: 600;
			color: $black;
			line-height: 42px;
			@media #{$md} {
				font-size: 32px;
				margin-top: 30px;
			}
			@media #{$xs} {
				font-size: 26px;
				margin-top: 28px;
				line-height: 28px;
			}
			@media #{$sm} {
				font-size: 32px;
				margin-top: 25px;
			}
		}
		& p{
			font-size: 18px;
			line-height: 28px;
			color: #666;
			padding-right: 45px;
			padding-top: 21px;
			padding-bottom: 32px;
			@media #{$lg} {
				font-size: 16px;
			}
			@media #{$md} {
				font-size: 15px;
				padding-right: 0;
			}
			@media #{$xs} {
				font-size: 16px;
				padding-right: 0;
			}

		}

		&.item-2{
			padding-left: 0;
			padding-right: 55px;
			@media #{$lg} {
				padding-right: 0;
			}
			@media #{$md} {
				padding-right: 0;
			}
			@media #{$xs} {
				padding-right: 0;
			}
		}
	}
	& .featured-news-thumb{
		position: relative;
		& > img{
			width: 100%;
		}
		& .featured-news-thumb-content{
			position: absolute;
			left: 50%;
			bottom: 50px;
			transform: translateX(-50%);
			width: 92%;
			background-color: rgba(0, 0, 0,.9);
			& .title{
				font-size: 40px;
				line-height: 42px;
				color: $white;
				font-weight: 600;
				margin-left: 58px;
				padding: 33px 0;
				@media #{$lg} {
					font-size: 36px;
				}
				@media #{$md} {
					font-size: 28px;
					line-height: 38px;
					margin-left: 20px;
				}
				@media #{$sm} {
					font-size: 22px;
					line-height: 38px;
					margin-left: 20px;
				}
			}
			& .featured-info{
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				background: $theme-color;
				width: 190px;
				text-align: center;
				padding-top: 30px;
				@media #{$sm} {
					width: 130px;
					padding-top: 25px;
				}
				& img{
					width: 70px;
					border-radius: 50%;
					border: 3px solid #fff;
				}
				& span{
					font-size: 18px;
					color: $white;
					font-weight: 500;
					text-transform: uppercase;
				}
			}
		}
	}
}


.featured-news-item{
	position: absolute;
	right: 20px;
	top: 20px;
	text-align: right;
	& .item{
		background: $theme-color;
		text-align: right;
		height: 102px;
		width: 102px;
		padding-right: 18px;
		& .title{
			color: $white;
			font-size: 50px;
			font-weight: 700;
			padding-top: 22px;
			line-height: 36px;
		}
		& span{
			color: $white;
			font-weight: 500;
			text-transform: uppercase;

		}
	}
	& a{
		height: 45px;
		width: 45px;
		text-align: center;
		line-height: 45px;
		font-size: 18px;
		color: $black;
		background: #dee3e1;
		margin-top: 5px;
	}
	&.item-2{
		left: 20px;
		& .item{
			padding-left: 18px;
			padding-right: 0;
		}
	}
}

.featured-userinfo{
	& span{
		font-size: 18px;
		font-weight: 500;
		color: #999;
		text-transform: uppercase;
		& img{
			border-radius: 50%;
			border: 3px solid #fff;
			box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
			margin-right: 6px;
		}
	}
	& ul{
		margin-left: 35px;
		margin-top: -6px;
		@media #{$lg} {
		margin-left: 24px;
		}
		@media #{$xs} {
		margin-left: 0px;
		margin-top: 10px;
		}
		@media #{$sm} {
		margin-left: 35px;
		margin-top: -6px;
		}
		& li{
			display: inline-block;
			color: #999;
			font-size: 18px;
			font-weight: 500;
			margin-right: 18px;
			& i{
				font-size: 16px;
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
}

.featured-article{
	.container{
		padding-top: 40px;
		@include media('>=tablet') {
			padding-top: 90px;
		}
	}
	p{
		margin: 0 0 10px;
	}
	h2{
		margin-bottom: 16px;
	}
	.col-holder{
		@include media('>=desktop') {
			max-width: 395px;
		}
	}
	.btn-orange{
		text-transform: uppercase;
	}
	.img-holder{
		overflow: hidden;
		a{
			display: block;
			&:hover{
				img{
					transform: scale(1.1);
				}
			}
		}
	}
	img{
		@include animate(transform);
	}
}