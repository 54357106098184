


/*===========================
    9.BANNER css 
===========================*/




.banner-area{
	height: 770px;
	position: relative;
	overflow: hidden;
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0,.5);
	}
	& .banner-content{
		overflow: hidden;
		& > span{
			color: $theme-color;
			font-weight: 800;
			font-size: 26px;
			position: relative;
			&::before{
				position: absolute;
				content: '';
				left: 0;
				bottom: 2px;
				height: 1px;
				width: 70px;
				background: $theme-color;
			}
		}
		& .title{
			font-size: 50px;
			color: $white;
			line-height: 55px;
			padding-top: 50px;
			padding-right: 40px;
			padding-bottom: 20px;
			@media #{$lg} {
				font-size: 32px;
				line-height: 43px;
			}
			@media #{$xs} {
				font-size: 40px;
				line-height: 42px;
				padding-right: 0;
			}
			@media #{$sm} {
				font-size: 38px;
				line-height: 40px;
				padding-right: 40px;
			}
		}
		& ul{
			padding-bottom: 86px;
			& li{
				font-size: 20px;
				font-weight: 500;
				color: #dfdfdf;
				text-transform: uppercase;
				&:first-child{
					color: $theme-color;
				}
			}
		}
		& .play{
			padding-bottom: 20px;
			padding-left: 15px;
			& a{
				& i{
					height: 90px;
					width: 90px;
					text-align: center;
					line-height: 90px;
					background: $theme-color;
					color: $white;
					font-size: 24px;
					border-radius: 50%;
					position: relative;
					z-index: 10;
					&::after{
						position: absolute;
						content: '';
						left: 0%;
						top: 0%;
						background-color: rgb(234, 112, 38);
						height: 100%;
						width: 100%;
						border-radius: 50%;
						z-index: -1;
						animation: video-pulse linear infinite 1.2s;
					}
				}
				& span{
					color: $white;
					font-size: 20px;
					font-weight: 700;
					text-transform: uppercase;
					padding-left: 32px;
				}
			}
		}
	}
	&.banner-5-area{
		&:after{
			content: '';
			background: rgba($black-2,0.5);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
		&:before{
			display: none !important;
		}
		.banner-content{
			.title{
				@include font(40px,45px);
				padding-right: 0;
				@include media('>=tablet') {
					@include font(50px,55px);
				}
				@include media('>=1400px') {
					@include font(60px,73px);
				}
			}
			p{
				@include font(14px,17px);
				color: $white;
			}
		}
	}
}

.banner-slide{
	& .slick-dots{
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translateX(-50%);
		& li{
			display: inline-block;
			& button{
				background: #fff;
				border: 0;
				height: 15px;
				width: 15px;
				margin: 0 5px;
				font-size: 0;
				border-radius: 50%;
			}
			&.slick-active{
				& button{
					background: $theme-color;
				}
			}
		}
	}
}





.page-title-area{
	height: auto;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	position: relative;
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: #020f50;
	}
	& .page-title-item{
		& .title{
			font-size: 60px;
			text-transform: uppercase;
			color: $white;
			letter-spacing: 0.4px;
			line-height: 60px;
            padding: 5px !important; 
			@media #{$xs} {
				font-size: 32px;
			}
		}
		& nav{
			display: inline-block;
			& ol{
				background-color: transparent;
				padding: 0;
				margin-bottom: 0;
				& li{
					color: #999;
					font-size: 20px;
					& a{
						color: #999;
					}
				}
			}
		}
	}
}
.breadcrumb-item + .breadcrumb-item{
	padding-left: 12px;
}
.breadcrumb-item + .breadcrumb-item::before {
	color: #999;
	content: "-";
	padding-right: 12px;
}

.visual-banner{
	position: relative;
	>.container{
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
	}
}

@media (max-width: 767px){
.page-title-area .page-title-item .title {
    font-size: 32px !important;
}}
.page-title-item.text-center {
    padding-top: 5px !important;
}