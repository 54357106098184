


/*===========================
    11.TRENDING NEWS css 
===========================*/





.trending-news-area{
	padding-top: 71px;
	& .trending-News-item{
		& .trending-news-thumb{
			position: relative;
			& img{
				width: 100%;

			}
			& span{
				position: absolute;
				top: 10px;
				left: 10px;
				background: #ff4657;
				color: $white;
				line-height: 24px;
				font-weight: 600;
				text-transform: uppercase;
				padding: 0 26px 0 13px;
				clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
				letter-spacing: .40px;
			}
			& i{
				position: absolute;
				top: -13px;
				right: 18px;
				height: 38px;
				width: 38px;
				text-align: center;
				line-height: 38px;
				background: #0084b4;
				color: $white;
				border-radius: 50%;
				z-index: 10;
				&::after{
					position: absolute;
					content: '';
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					height: 50px;
					width: 50px;
					opacity: .3;
					z-index: -1;
					background-color: rgb(0, 132, 180);
					border-radius: 50%;
				}
			}
		}
		& .trending-news-content{
			& span{
				font-size: 20px;
				font-weight: 600;
				color: $theme-color;
				text-transform: uppercase;
				padding-top: 11px;
				position: relative;
				&::before{
					position: absolute;
					content: '';
					right: -25px;
					bottom: 7px;
					height: 1px;
					width: 18px;
					background: $theme-color;
				}
			}
			& .title{
				font-size: 26px;
				line-height: 28px;
				color: $black;
				font-weight: 600;
				padding-top: 3px;
				@media #{$md} {
					font-size: 22px;
					line-height: 24px;
				}
			}
		}
		&.item-2{
			& .trending-news-thumb{
				& span{
					background: #dd27b9;
				}
			}
		}
		&.item-3{
			& .trending-news-thumb{
				& span{
					background: #f1aa2e;
				}
			}
		}
		&.item-4{
			& .trending-news-thumb{
				& span{
					background: #469dff;
				}
			}
		}

	}
	& .trending-slide{
		& .slick-arrow{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 52px;
			height: 76px;
			text-align: center;
			line-height: 76px;
			background: $white;
			color: $black;
			cursor: pointer;
			z-index: 9;
			box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
			@include transition(0.3s);
			box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
			&:hover{
				background: $theme-color;
				color: $white;
			}
			&.prev{
				left: -66px;
			}
			&.next{
				right: -66px;
			}
		}
	}
	&.trending-news-4-area{
		& .section-title{
			& .title{
				color: $white;
				border-bottom-color: #292929;
			}
		}
		& .trending-News-item {
			& .trending-news-content{
				& .title{
					color: $white;
				}
			}
		}
	}
	.new-home &{
		padding-top: 0;
		.container{
			padding-top: 61px;
			padding-bottom: 61px;
		}
		.sub-title{
			color: $black-2;
		}
		.trending-News-item{
			position: relative;
			&:hover{
				.trending-news-thumb{
					img{
						transform: scale(1.05);
					}
				}
			}
		}
		.trending-news-thumb{
			a{
				overflow: hidden;
				display: block;
			}
			img{
				@include animate(transform);
			}
		}
		.trending-news-content{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			padding: 14px;
		}
		
		.trending-slide{
			.sub-title{
				@include font(25px,30px);
				color: $theme-orange;
			}
			.slick-arrow{
				@include size(60px);
				background: $theme-orange;
				color: $white;
				&.prev{
					@include media('<1400px') {
						left: 15px;
					}
				}
				&.next{
					@include media('<1400px') {
						right: 15px;
					}
				}
			}
		}

		.btn-orange{
			@include font(16px,20px);
			max-width: 100px;
			&.radius{
				padding: 9px;
			}
		}
	}
}
