



/*===========================
    15.BLOG css 
===========================*/





.blog-details-news{
	& .news-item-thumb{
		& i{
			position: absolute;
			top: 20px;
			right: 20px;
			height: 45px;
			width: 45px;
			text-align: center;
			line-height: 45px;
			background: #000;
			color: $white;
			border-radius: 50%;
			cursor: pointer;
			font-size: 20px;
			@include transition(0.3s);
			&:hover{
				background: $theme-color;
				color: $white;
			}
		}
	}
	& .author-item-sub{
		padding-bottom: 45px;
	}
	& .news-item-content{
		& .title{
			&:hover{
				color: $black;
			}
		}
		& p{
			padding-right: 0 !important;
			margin-left: -1px;
			padding-bottom: 37px !important;
		}
	}
}


.blog-quote{
	position: relative;
	text-align: center;
	z-index: 10;
	height: 220px;
	margin-top: 35px;
	margin-top: 80px;
	@media #{$xs} {
		height: auto;
	}
	@media #{$sm} {
		height: 220px;
	}
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-image: url(../images/quote-bg.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}
	& p{
		color: $white;
		font-size: 32px;
		font-weight: 500;
		line-height: 36px;
		font-family: $bar;
		padding: 65px 100px 5px;
		@media #{$lg} {
			font-size: 30px;
			padding: 65px 90px 5px;
		}
		@media #{$xs} {
			font-size: 26px;
			padding: 65px 10px 5px;
			line-height: 30px;
		}
		@media #{$sm} {
			font-size: 28px;
			padding: 65px 30px 5px;
		}
	}
	& span{
		color: $white;
		font-size: 22px;
		font-family: $bar;
		@media #{$xs} {
			padding-bottom: 20px;
		}
		@media #{$sm} {
			padding-bottom: 0px;
		}
	}
	& i{
		position: absolute;
		left: 50%;
		top: -33px;
		transform: translateX(-50%);
		height: 75px;
		width: 75px;
		text-align: center;
		line-height: 75px;
		background: #000;
		color: $white;
		border-radius: 50%;
		&::after{
			position: absolute;
			content: '';
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 55px;
			width: 55px;
			border-radius: 50%;
			border: 1px dashed #fff;
		}
	}
}



.blog-details-area{
	& .blog-contents{
		padding-top: 37px;
		& p{
			font-size: 18px;
			color: #666;
			line-height: 32px;
			margin-left: -1px;
		}
	}
	& .blog-thumb{
		position: relative;
		& a{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 90px;
			width: 90px;
			text-align: center;
			line-height: 90px;
			border-radius: 50%;
			background: $theme-color;
			color: $white;
			font-size: 28px;
			z-index: 5;
			@media #{$xs} {
				height: 70px;
				width: 70px;
				line-height: 70px;
				font-size: 20px;
			}
			&::before{
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				background: $theme-color;
				z-index: -1;
				border-radius: 50%;
				animation: video-pulse linear infinite 1.2s;
			}
		}
		& img{
			width: 100%;
		}
	}
	& .blog-share{
		background: #f9f9f9;
		height: 75px;
		padding-left: 30px;
		padding-right: 10px;
		margin-top: 45px;
		@media #{$xs} {
			height: auto;
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 10px;
		}
		& .blog-share-tag{
			@media #{$xs} {
				margin-bottom: 10px;
			}
			& ul{
				& li{
					display: inline-block;
					font-family: $bar;
					font-size: 18px;
					color: #777;
					&:first-child{
						font-weight: 600;
						color: $theme-color;
						padding-right: 6px;
					}
				}
			}
		}
	}
	& .blog-author{
		background: #f9f9f9;
		height: 185px;
		margin-top: 50px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		@media #{$xs} {
			height: auto;
		}
		@media #{$sm} {
			height: 185px;
		}
		& .blog-author-item{
			padding-left: 185px;
			position: relative;
			@media #{$xs} {
				padding-left: 10px;
				padding-top: 10px;
			}
			@media #{$sm} {
				padding-left: 150px;
			}
			& .title{
				font-size: 30px;
				font-weight: 700;
				color: $black;
				text-transform: uppercase;
				& span{
					text-transform: capitalize;
					font-size: 18px;
					font-weight: 400;
					color: $theme-color;
				}
			}
			& p{
				font-size: 18px;
				color: #666;
				padding-right: 40px;
				padding-top: 9px;
				@media #{$xs} {
					font-size: 15px;
				}
			}
			& .blog-thumb{
				position: absolute;
				left: 55px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 5;
				@media #{$xs} {
					left: 0px;
					position: inherit;
					top: 44px;
				}
				@media #{$sm} {
					left: 30px;
					top: 50%;
					position: absolute;
				}
				&::before{
					position: absolute;
					content: '';
					left: 50%;
					top: 50%;
					height: 125px;
					width: 125px;
					transform: translate(-50%, -50%);
					z-index: -1;
					border: 1px dashed #999;
					border-radius: 50%;
					@media #{$xs} {
						display: none;
					}
					@media #{$sm} {
						display: block;
					}
				}
				& img{
					border-radius: 50%;
					width: auto;
				}
			}
		}
	}
	& .blog-news-item{
		margin-top: 50px;
		flex-wrap: nowrap;
		& .blog-news-item-1{
			background: #333;
			padding-left: 50px;
			padding-right: 0px;
			width: 50%;
			padding-bottom: 34px;
			@media #{$xs} {
				padding-left: 20px;
			}
			& .title{
				font-size: 22px;
				font-weight: 500;
				color: $white;
				padding-top: 33px;
				padding-bottom: 4px;
				@media #{$xs} {
					font-size: 18px;
				}
				@media #{$sm} {
					font-size: 20px;
				}
			}
			& a{
				font-weight: 500;
				text-transform: uppercase;
				color: $white;
				opacity: .5;
				@include transition(0.3s);
				&:hover{
					color: $theme-color;
					opacity: 1;
				}
				& i{
					padding-right: 5px;
				}
			}
			&.blog-news-item-2{
				background: #414141;
				padding-left: 0;
				padding-right: 50px;
				@media #{$xs} {
					padding-right: 20px;
				}
			}
		}
	}
	& .blog-comments{
		& .blog-comments-title{
			& .title{
				font-size: 34px;
				font-weight: 700;
				text-transform: uppercase;
				color: #222;
			}
		}
		& .blog-comments-item{
			padding-left: 155px;
			margin-top: 30px;
			position: relative;
			margin-bottom: 50px;
			@media #{$xs} {
				padding-left: 0;
				margin-left: 0;
			}
			@media #{$sm} {
				padding-left: 155px;
			}
			
			& .title{
				font-size: 30px;
				font-weight: 700;
				color: $black;
				text-transform: uppercase;
				@media #{$xs} {
					font-size: 24px;
				}
				& span{
					text-transform: capitalize;
					font-size: 16px;
					font-weight: 400;
					color: $theme-color;
				}
			}
			& p{
				font-size: 18px;
				color: #666;
				padding-top: 11px;
				@media #{$xs} {
					font-size: 15px;
				}
			}
			& .thumb{
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 120px;
				width: 120px;
				@media #{$xs} {
					position: inherit;
					top: 70px;
				}	
				@media #{$sm} {
					position: absolute;
					top: 50%;
				}	
				& img{
					width: 100%;
				}

			}
			& a{
				position: absolute;
				top: 0;
				right: 0;
				font-size: 18px;
				font-weight: 700;
				text-transform: uppercase;
				color: $theme-color;
			}

		}
	}
	& .blog-comments-input{
		& .blog-comments-title{
			& .title{
				font-size: 34px;
				font-weight: 700;
				text-transform: uppercase;
				color: #222;
				padding-bottom: 11px;
			}
		}
		& form{
			& .input-box{
				& input{
					width: 100%;
					border: 0;
					background: #f9f9f9;
					height: 70px;
					padding-left: 35px;
					font-family: $rob;
					color: #999;
					&::placeholder{
						opacity: 1;
					}

				}
				& textarea{
					width: 100%;
					resize: none;
					height: 200px;
					border: 0;
					background: #f9f9f9;
					padding-left: 35px;
					padding-top: 20px;
					font-family: $rob;
					color: #999;
					&::placeholder{
						opacity: 1;
					}
				}
				& button{
					background: $theme-color;
					color: $white;
					border-color: $theme-color;
					margin-top: 20px;
					letter-spacing: 1px;
				}
			}
		}
	}

}



.post-content {
	max-width: 960px;
	margin: 30px auto;
}
.blog-details-news {
	.news-item-thumb {
		min-height: 350px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			max-width: 90%;
			max-height: 90%;
		}
		&::after {
			box-sizing: border-box;
			border-top: 40px solid transparent;
			border-bottom: 40px solid transparent;
		}
	}
	.news-item-content {
		.title {
			max-height: auto;
		}
	}
}
.blog-details-area {
	h2, h3 {
		margin: 32px 0;
		position: relative;
		&::after {
			content: "";
		  position: absolute;
			bottom: -2px;
			left: 0;
			width: 100px;
			height: 2px;
			background: #ea7026;
		}
	}
}
