/*===========================
    19.ARTICLE POST css 
===========================*/

.post-card{
	background: $white-2;
	overflow: hidden;
	&:hover{
		.post-img{
			img{
				transform: scale(1.1);
			}
		}
		.btn-arrow{
			i{
				margin-left: 10px;
			}
		}
	}
	.title{
		@include font(18px,22px);
		margin-bottom: 12px;
		display: block;
		font-weight: bold;
		a{
			color: $black-2;
			&:hover{
				color: $theme-orange;
			}
		}
	}
	p{
		color: #b1b1b1;
	}
	.btn-arrow{
		color: $white;
		position: absolute;
		right: 0 !important;
		bottom: 0;
		i{
			@include animate(margin);
		}
		&:hover{
			background: lighten($theme-orange,15%);
		}
	}
	.post-img{
		@include media('>=tablet') {
			padding-right: 15px;
		}
		a{
			display: block;
			overflow: hidden;
		}
		img{
			@include animate(transform);
		}
	}
	.post-content{
		padding: 10px 85px 10px 15px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	/*===== Vertical Articles cards style =====*/
	&.vertical{
		text-align: center;
		.post-img{
			padding: 0;
			position: relative;
			z-index: 2;
			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				background: rgba($black,0.4);
				pointer-events:none;
			}
		}
		.post-content{
			padding: 20px 46px;
		}
		.tag-title{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			padding: 10px;
			font-family: $rob;
			pointer-events: none;
			z-index: 2;
		}
		.category-title{
			@include font(26px,30px);
			text-transform: uppercase;
			letter-spacing: 0.55px;
			text-align: left;
			pointer-events:auto;
			a{
				color: $white;
			}
		}
	}
}

.hashtags{
	@include font(14px,18px);
	display: flex;
	justify-content: flex-end;
	pointer-events:auto;
	margin-bottom: 15px;
	li{
		margin-left: 25px;
	}
	a{
		color: $white;
		&:hover{
			color: $theme-orange;
		}
	}
}

/*===== Articles photos post =====*/
.articles-photos{
	.row{
		>.col,
		>[class*="col-"] {
			padding-bottom: 15px;
			@include media('>=desktop') {
				padding-bottom: 60px;
			}
		}
		.row{
			min-height: 100%;
			>.col,
			>[class*="col-"] {;
				@include media('>=desktop') {
					padding-bottom: 0;
				}
			}
		}
	}
	.container-fluid{
		@include media('>=1920px') {
			padding: 0 45px;
		}
	}
	.col-holder{
		position: relative;
		border: 1px solid $grey-3;
		z-index: 2;
		border-radius: 6px;
		overflow: hidden;
		min-height: 225px;
		@include media('>=phone') {
			min-height: 0;
		}
		@include media('>=tablet') {
			min-height: 400px;
		}
		@include media('>=desktop') {
			min-height: 0;
		}
		&:hover{
			.img-holder{
				transform: scale(1.1);
			}
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background: rgba($black,0.4);
			pointer-events:none;
		}
	}
	.img-holder{
		@include animate(transform);
		min-height: inherit;
		img{
			object-fit:cover;
			height: 100%;
			min-height: inherit;
		}
		a{
			min-height: inherit;
			width: 100%;
		}
	}
	.content-holder{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		pointer-events:none;
		padding: 15px;
		@include media('>=tablet') {
			padding: 32px;
		}
	}
	.play-btn{
		@include box(50px);
		@include animate(transform);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 2;
		background: $theme-orange;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		@include media('>=desktop') {
			@include box(88px);
			padding: 0;
		}
		&:hover{
			transform: translate(-50%,-50%) scale(1.05) rotate(360deg);
		}
	}
	.post-info{
		pointer-events:auto;
		max-width: 467px;
	}
	.category-title{
		@include font(16px,20px);
		letter-spacing: 0.55px;
		text-transform: uppercase;
		display: block;
		margin-bottom: 10px;
		@include media('>=widescreen') {
			@include font(26px,30px);
			margin-bottom: 19px;
		}
		a{
			color: $white;
		}

	}
}