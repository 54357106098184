


/*===========================
    14.TEAM css 
===========================*/



.team-area{
	& .team-item{
		position: relative;
		margin-bottom: 20px;
		@include transition(0.3s);
		& img{
			width: 100%;
		}
		& .team-overlay{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: rgba(0, 0, 0,.7);
			padding-top: 25px;
			padding-bottom: 28px;
			& .title{
				color: $white;
				font-weight: 600;
				font-size: 28px;
				text-transform: uppercase;
				line-height: 26px;

			}
			& span{
				color: $theme-color;
				text-transform: capitalize;
				font-size: 16px;
			}
			& i{
				position: absolute;
				top: -20px;
				right: 20px;
				color: #a3a3a3;
				height: 45px;
				width: 45px;
				text-align: center;
				line-height: 45px;
				background: $white;
				border-radius: 50%;
			}
		}
	}
	& .row{
		& .col-lg-3{
			padding-left: 0;
			padding-right: 0;
		}
	}
	& .team-slide{
		& .slick-center{
			& .team-item{
				transform: scale(1.08);
				z-index: 99;
				position: relative;
				& .team-overlay{
					background-color: rgba(0, 0, 0,1);
					& i{
						background: #e71919;
						color: $white;
					}
				}
			}

		}
	}

}
