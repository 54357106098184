


/*===========================
    7.MORE NEWS css 
===========================*/




.more-news-area{
	padding-bottom: 62px;
	& .section-title{
		margin-bottom: 20px;
	}

}

.more-news-item{
	margin-bottom: 18px;
	& .more-news-thumb{
		position: relative;
		& img{
			width: 100%;
		}
	}
	& .more-news-content{
		& .title{
			font-size: 30px;
			line-height: 30px;
			color: $black;
			font-weight: 600;
			padding-top: 17px;
			padding-bottom: 15px;
			@media #{$lg} {
				font-size: 26px;
			}
			@media #{$md} {
				font-size: 28px;
			}
			@media #{$xs} {
				font-size: 24px;
			}
			@media #{$sm} {
				font-size: 30px;
			}

		}
	}
}