/*===========================
    18.TESTIMONIALS css 
===========================*/


.testimonials-section{
	background: $white-2;
	.container{
		padding-top: 30px;
		padding-bottom: 30px;
		@include media('>=desktop') {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	h2{
		margin-bottom: 36px;
	}
	blockquote{
		font-style: normal;
		margin-bottom: 46px;
	}
	cite{
		font-style: normal;
		margin-bottom: 22px;
		display: block;
		a{
			display: flex;
			align-items: center;
		}
		.author-img{
			flex-shrink: 0;
			margin-right: 10px;
		}
		.name{
			color: $theme-color;
			display: block;
		}
		.designation{
			color: rgba($black-2,0.5);
		}
	}
	q{
		@include font(14px,18px);
		color: $black-2;
		font-family: $rob;
		display: block;
	}
	.col-holder{
		@include media('>=desktop') {
			max-width: 359px;
		}
	}
}

.testimonials-slide{
	.slick-arrow{
		right: calc(50% - 91px) !important;
		transform: translateX(-50%);
		bottom: 0;
		&.next{
			background: $white;
		}
		&.prev{
			right: calc(50% - 40px) !important;
			background: $theme-orange;
		}
	}
	.slick-dots{
		overflow: hidden;
		display: flex !important;
		justify-content: center;
		margin: 30px -5px 0;
		@include media('>=desktop') {
			position: absolute;
			bottom: 27px;
			left: 15px;
			justify-content: flex-start;
			margin: 0 -5px;
		}
		@include media('>=widescreen') {
			bottom: 65px;
		}
		li{
			flex-shrink: 0;
			padding: 0 5px;
			&.slick-active{
				button{
					background: $theme-orange;
				}
			}
		}
		button{
			@include box(10px);
			border:none;
			padding: 0;
			text-indent: -9999px;
			background: $grey-2;
			display: block;
		}
	}
	.img-holder{
		overflow: hidden;
		display: block;
		&:hover{
			img{
				transform: scale(1.1);
			}
		}
	}
	img{
		@include animate(transform);
	}
}