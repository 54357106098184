


/*===========================
    3.AUTHOR NEWS css 
===========================*/



.author-news-area{
	& .author-item{
		position: relative;
		overflow: hidden;
		& img{
			width: 100%;
			@include transition(0.3s);
		}
		& .author-item-overlay{
			background-color: rgba(232, 80, 62,.8);
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			& .author-item-content{
				padding-left: 30px;
				padding-top: 20px;
				padding-right: 30px;
				& span{
					font-size: 18px;
					font-weight: 600;
					color: $white;
					position: relative;
					&::before{
						position: absolute;
						content: '';
						left: 0;
						bottom: 0;
						height: 1px;
						width: 60px;
						background: $white;
					}
				}
				& .title{
					font-weight: 600;
					color: $white;
					line-height: 34px;
					font-size: 30px;
					padding-top: 16px;
					@media #{$lg} {
						font-size: 24px;
						line-height: 26px;
					}
					@media #{$xs} {
						font-size: 24px;
						line-height: 26px;
					}
				}
			}
			& .author-item-sub{
				position: absolute;
				left: 30px;
				bottom: 30px;
				width: 82%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				& ul{
					& li{
						display: inline-block;
						color: $white;
						font-size: 18px;
						font-weight: 500;
						margin-right: 18px;
						& i{
							font-size: 16px;
						}
					}
				}
				& span{
					background: #e71919;
					line-height: 27px;
					padding: 0 16px;
					border-radius: 20px;
					color: $white;
					font-size: 18px;
					font-weight: 500;
					& i{
						font-size: 16px;

					}
				}
			}
		}
		&.item-2{
			& .author-item-overlay{
				background-color: rgba(86, 180, 73,.8);
			}
		}
		&.item-3{
			& .author-item-overlay{
				background-color: rgba(43, 125, 225,.8);
			}
		}
		&:hover{
			& img{
				transform: scale(1.1);
			}
		}
		& .author-content{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0,.8);
			padding-left: 30px;
			padding-top: 20px;
			padding-right: 40px;
			@media #{$lg} {
				padding-left: 25px;
				padding-right: 25px;
			}
			& p{
				font-size: 20px;
				line-height: 32px;
				color: #cecece;
				@media #{$lg} {
					font-size: 16px;
				}
				@media #{$xs} {
					font-size: 14px;
				}
				@media #{$sm} {
					font-size: 18px;
				}
			}
			& a{
				color: #cecece;
				font-size: 20px;
				font-weight: 500;
				text-transform: uppercase;
				position: absolute;
				left: 30px;
				bottom: 22px;
				@media #{$lg} {
					font-size: 18px;
				}
				@media #{$xs} {
					font-size: 18px;
				}
				& i{
					padding-left: 5px;
				}
			}
		}
	}
}





.author-news-item{
	position: relative;
	overflow: hidden;
	& img{
		width: 100%;
	}
	& .author-news-overlay{
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0,.8);
		& .author-thumb{
			position: absolute;
			left: 50%;
			top: 50px;
			transform: translateX(-50%);
			width: 250px;
			height: 250px;
			@media #{$lg} {
				width: 150px;
				height: 150px;
			}
			@media #{$xs} {
				height: 130px;
				width: 130px;
			}
			&::before{
				position: absolute;
				content: '';
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: 220px;
				width: 220px;
				border-radius: 50%;
				border: 2px dashed #fff;
				@media #{$lg} {
					height: 170px;
					width: 170px;
				}
				@media #{$xs} {
					height: 110px;
					width: 110px;
				}
			}
			& img{
				width: 100%;
				border-radius: 50%;
			}
		}
		& .author-content{
			padding-left: 50px;
			padding-bottom: 50px;
			& span{
				color: $theme-color;
				font-size: 18px;
				font-weight: 600;
				position: relative;
				&::before{
					position: absolute;
					content: '';
					left: 0;
					bottom: 0;
					width: 60px;
					height: 1px;
					background: $theme-color;
				}
			}
			& .title{
				color: $white;
				font-size: 40px;
				line-height: 36px;
				font-weight: 700;
				text-transform: uppercase;
				padding-right: 100px;
				padding-top: 22px;
				padding-bottom: 9px;
				@media #{$lg} {
					font-size: 34px;
					line-height: 32px;
					padding-top: 15px;
				}
				@media #{$xs} {
					font-size: 34px;
					line-height: 32px;
					padding-top: 15px;
				}
			}
			& p{
				font-size: 18px;
				color: #999;
				padding-bottom: 37px;
				@media #{$lg} {
					font-size: 16px;
					padding-bottom: 15px;
				}
				@media #{$xs} {
					font-size: 15px;
					padding-bottom: 15px;
				}
				@media #{$sm} {
					font-size: 18px;
					padding-bottom: 25px;
				}
			}
		}
	}
}