


/*===========================
    2.HEADER css 
===========================*/



.header-area{
	margin-top: 25px;
background: #020F50;
	& .header-logo{
		& img{
			@media #{$xs} {
				width: 75%;
			}
		}
	}

	& .header-search{
		& .input-box{
			position: relative;
			& input{
				width: 102px;
				border: 0;
				border-bottom: 2px solid #ccc;
				padding-left: 10px;
				font-size: 17px;
				color: #333;
				line-height: 40px;
				font-weight: 500;
				&::placeholder{
					opacity: 1;
					text-transform: uppercase;
				}
			}
			& button{
				border: 0;
				background-color: transparent;
				color: $black;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 20px;
			}
		}
	}
	& .header-toggler{
		width: 55px;
		height: 40px;
		/*border: 2px solid #000;*/
		text-align: center;
		cursor: pointer;
		line-height: 40px;
		border-radius: 5px;
		& i{
			color: white;
			font-size: 24px;
			vertical-align: middle;

		}
	}
	& .header-menu{
		margin-top: 29px;
		& ul{
			& li{
				display: inline-block;
				& a{
					font-size: 18px;
					font-weight: 700 !important;
					color: $white;
					padding: 0 23px;
					line-height: 12px;
					border-right: 1px solid #ccc;
				
					text-transform: uppercase;
					@include transition(0.3s);
					@media #{$md} {
						font-size: 16px;
						padding: 0 18px;
					}
				}
				&:last-child{
					& a{
						border-right: 0;
					}
				}
			}
		}
	}
	&.header-2-area{
		margin-top: 0;
        background-color: #020F50;
        
		& .header-headline-area{
			background: $grey;
		}
		& .header-headline{
			margin-top: 0;
            bacground-color: #020F50;
			& .headline-slide{
				& .item{
					padding: 16px 0;
					& p{
						display: inline-block;
					}
				}
				& .slick-arrow{
					border-radius: 4px;
					&.prev{
						right: 120px;
						left: auto;
						@media #{$lg} {
							right: 300px;
						}
						@media #{$md} {
							right: 60px;
						}
					}
					&.next{
						right: 60px;
						@media #{$lg} {
							right: 240px;
						}
						@media #{$md} {
							right: 0px;
						}
					}
				}
			}
			& .header-social{
				width: 300px;
				@media #{$lg} {
					margin-left: -235px;
				}
				& ul{
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					& li{
						margin-left: 2px;
					}
				}
			}
		}
		& .main-header{
			position: relative;
			& .header-menu{
				margin-top: 0;
				& ul{
					& li{
						& > a{
							padding: 10px 13px;
							border-right: 0;
							@media #{$lg} {
								font-size: 15px;
								padding: 10px 10px;
							}
						}
					}
				}
			}
			& .header-sub{
				@media #{$md} {
					position: absolute;
					right: 100px;
					top: 50%;
					transform: translateY(-50%);
				}
				@media #{$xs} {
					display: none;
				}
				@media #{$sm} {
					position: absolute;
					right: 100px;
					top: 50%;
					transform: translateY(-50%);
					display: block;
				}
				& ul{
					& li{
						display: inline-block;
						margin-left: 5px;
						& a{
							height: 50px;
							width: 50px;
							text-align: center;
							line-height: 50px;
							border-radius: 50%;
							color: #0084b4;
							background: #e5f2f7;
							font-size: 18px;
						}
						&:nth-child(2) {
							& a{
								background: #fde8e8;
								color: #e71919;
							}
						}
						&:nth-child(3) {
							& a{
								background: #f6f6f6;
								color: $black
							}
						}
					}
				}
			}
		}
		&.header-3-area {
			background: #000;
			& .header-point{
				& .header-menu{
					& ul{
						& li{
							& > a{
								color: $white;
								border-right-color: #3f3f3f;
								&.active{
									color: $theme-color;
								}
							}
							&:first-child{
								& a{
									padding-left: 0;
								}
							}
							&:last-child{
								& a{
									padding-right: 0;
									border-right: 0;
								}
							}
							& .sub-menu{
								& li{
									& a{
										color: $black;
										padding-left: 20px;
										&:hover{
											color: $white;
										}
									}
								}
							}
						}
					}
				}
				& .header-search{
					& .input-box{
						position: relative;
						& input{
							width: 102px;
							border: 0;
							border-bottom: 2px solid #3b3b3b;
							padding-left: 10px;
							font-size: 17px;
							color: #fff;
							line-height: 40px;
							font-weight: 500;
							background-color: transparent;
							&::placeholder{
								opacity: 1;
								text-transform: uppercase;
							}
						}
						& button{
							border: 0;
							background-color: transparent;
							color: $white;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							font-size: 20px;
						}
					}
				}
			}
			& .header-toggler{
				border-color: $white;
				& i{
					color: $white;
				}
			}

		}
	}
	&.header-4-area{
		background: #0e0e0e;
		& .header-headline-area{
			background: #000;
			& .header-headline{
				background: #000;
			}
		}
		& .header-menu {
			& ul{
				& li{
					& a{
						color: $white;
						&:hover,
						&.active{
							color: $theme-color;
						}
					}
					& .sub-menu{
						& li{
							& a{
								color: #000;
								&:hover{
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		
		& .main-header {
			& .header-sub{
				& ul{
					& li{
						& a{
							background: #0d1a1f;
						}
						&:nth-child(2){
							& a{
								background: #240f0f;
							}
						}
						&:nth-child(3){
							& a{
								background: #1a1a1a;
								color: #fff;
							}
						}
					}
				}
			}
		} 
	}
	&.header-5-area{
			z-index: 99;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			.py-20{
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.header-menu{
				>ul{
					>li{
						>a{
							color: $white;
							&.active{
								color: $theme-orange;
							}
							&:hover{
								color: $theme-orange;
							}
						}
					}
				}
			}
			.header-toggler{
				border-color: $white;
				i{
					color: $white;
				}
			}
		}
}



.header-social{
	& ul{
		& li{
			display: inline-block;
			& a{
				height: 40px;
				width: 40px;
				text-align: center;
				line-height: 40px;
				background: #5e5eb7;
				color: $white;
				margin-right: 2px;
			}
			&:nth-child(2){
				& a{
					background: #c72026;
				}
			}
			&:nth-child(3){
				& a{
					background: #29a4dd;
				}
			}
			&:nth-child(4){
				& a{
					background: #42548e;
				}
			}
		}
	}
}


.header-headline{
	background: $grey;
	margin-top: 25px;
	& .headline-slide{
		& .item{
			padding: 22px 0;
			& p{
				color: $body-text;
				@media #{$md} {
					font-size: 15px;
				}
				& a{
					font-weight: 700;
					text-decoration: underline;
					text-transform: uppercase;
					color: $body-text;
					@include media('>=tablet') {
						margin-left: 10px;
					}
				}
				& span{
					color: $theme-color;
					font-weight: 600;
					padding-right: 6px;
					& i{
						padding-right: 4px;
					}
				}
			}
		}
		& .slick-arrow{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 5;
			cursor: pointer;
			height: 50px;
			width: 46px;
			line-height: 50px;
			text-align: center;
			font-size: 18px;
			color: #000;
			background: $white;
			@include transition(0.3s);
			&:hover{
				background: $theme-color;
				color: $white;

			}
			&.prev{
				left: 10px;
			}
			&.next{
				right: 10px;
			}
		}
	}

}



/*===========================
    MOBILE MENU css 
===========================*/


.offcanvas-menu {
    position: fixed;
    top: 0;
    left: -400px;
    width: 400px;
    height: 100%;
    background-color: #fff;
    z-index: 99999999;
    padding: 100px 30px 30px;
    overflow-y: auto;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;

    @media #{$xs} {
        width: 320px;
    }
    & .close-mobile-menu{
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 20px;
        color: #2c2c2c;
    }
    & .mobile-menu{
        padding-bottom: 30px;
        & ul{
            & li{
                position: relative;
                border-bottom: 1px solid rgba(32, 31, 31, 0.4);
                & a{
                    display: block;
                    font-size: 18px;
                    font-weight: 400;
                    color: #201f1f;
                    padding: 8px 0;
                }
                & .sub-menu{
                    padding-left: 20px;
                    & ul{
                        & li{
                            border-bottom: 0;
                            padding-left: 20px;
                        }
                    }
                    & .mega-title{
                        & > a{
                            font-weight: 500;
                        }
                    }
                }
                & .menu-expand{
                    position: absolute;
                    right: 10px;
                    top: 11px;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: #201f1f;
                        top: 10px;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        width: 1px;
                        height: 100%;
                        background-color: #201f1f;
                        left: 10px;
                        bottom: 0;
                        -webkit-transition: all 0.3s linear;
                        transition: all 0.3s linear;
                    }
                }
                &.active{
                    & > .menu-expand{
                        &::after{
                            height: 0;
                        }
                    }
                }
            }
        }
    }
}

.offcanvas-menu.open {
    left: 0;
}




.overlay {
    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0;
        z-index: 99999;
        visibility: visible;
        @include transition(0.2s);
        visibility: hidden;
    }

    &.open {
        &::before {
            opacity: 0.5;
            visibility: visible;
        }
    }
}




  /* Sub Menu CSS */
  .header-menu {
  	& ul{
  		& li{
  			position: relative;
  			& > .sub-menu{
  				top: calc(100% + 50px);
  				&::before{    
  					position: absolute;
				    top: -20px;
				    left: 25px;
				    border-color: transparent transparent #ffffff;
				    border-style: solid;
				    border-width: 10px 6px;
				    content: "";
				    z-index: 1;

  				}
  				&::after{
				    position: absolute;
				    top: -15px;
				    left: 0px;
				    content: "";
				    width: 100%;
				    height: 15px;

  				}
  			}
  			&:hover{
  				& .sub-menu{
  					display: block;
  				}
  				
  			}
  		}
  		& .sub-menu{
		    min-width: 220px;
		    position: absolute;
		    z-index: -9;
		    margin: 0;
		    padding: 10px 0 15px;
		    opacity: 0;
		    top: 0;
		    background-color: #ffffff;
		    visibility: hidden;
		    transition: all 0.35s;
		    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
		    &::before{
			    position: absolute;
			    top: -20px;
			    left: 25px;
			    border-color: transparent transparent #ffffff;
			    border-style: solid;
			    border-width: 10px 6px;
			    content: "";
			    z-index: 1;

		    }
		    &::after{
			    position: absolute;
			    top: -15px;
			    left: 0px;
			    content: "";
			    width: 100%;
			    height: 15px;

		    }
		    & li{
		    	position: relative;
		    	display: block;
		    	& a{    
		    		background-color: transparent;
				    border-right: 0 none;
				    text-align: left;
				    display: block;
				    line-height: 1.4em;
				    padding: 10px 25px;
				    text-transform: uppercase;
				    transition: all 0.3s;	
					font-size: 18px;
					font-weight: 500;
					color: $black;    		

		    	}
		    	&:last-child{
		    		& a{
		    			border-bottom: none;
		    		}
		    		& > a{
		    			border-bottom: 0;
		    		}
		    	}
		    	&:hover{
		    		& .sub-menu{
					    opacity: 1;
					    list-style: none;
					    z-index: 99;

		    		}
		    	}
		    }
		    & .sub-menu{
			    min-width: 250px;
			    position: absolute;
			    left: calc(100% + 25px);
			    top: 0px;
			    margin: 0;
			    padding: 10px;
			    opacity: 0;
			    transition: all 0.35s;
			    z-index: -9;
			    &::before{
			    position: absolute;
			    content: "";
			    left: -20px;
			    top: 0;
			    height: 40px;
			    width: 20px;

			    }
		    }
  		}
  	}
  }





  /*Animation*/


  .header-menu ul li:hover > .sub-menu {
    top: calc(100% + 20px);
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
  /* Default Color */
  .header-menu ul li:hover > a,
  .header-menu ul > li > a:hover,
  .header-menu ul > li > a.active {
    background-color: transparent;
    text-decoration: none;
    color: #FFA423;
  }
  .header-menu ul .sub-menu > li > a:hover,
  .header-menu ul .sub-menu .sub-menu > li > a:hover,
  .header-menu ul .sub-menu .sub-menu .sub-menu > li > a:hover {
    background-color: $theme-color;
    text-decoration: none;
    color: #ffffff;
  }




.header-logo {
	img {
		/*max-width: 150px;*/
	}
}

