


/*===========================
    13.POPULER css 
===========================*/



.populer-area{}


.populer-item{
	position: relative;
	overflow: hidden;
	& img{
		width: 100%;
		@include transition(0.3s);
	}

	& .populer-overlay{
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0,.6);
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: flex-end;
		& .populer-content{
			padding-left: 50px;
			padding-bottom: 46px;
			@media #{$md} {
				padding-right: 200px;
			}
			@media #{$xs} {
				padding-left: 20px;
				padding-bottom: 20px;
			}
			& span{
				color: $white;
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
				position: relative;
				&::before{
					position: absolute;
					content: '';
					left: 0;
					bottom: 0;
					height: 1px;
					width: 116px;
					background: #969696;
				}
			}
			& .title{
				font-weight: 600;
				font-size: 30px;
				line-height: 34px;
				color: $white;
				padding-right: 100px;
				padding-top: 17px;
				padding-bottom: 12px;
				@media #{$lg} {
					font-size: 23px;
				}
				@media #{$xs} {
					padding-right: 50px;
					font-size: 22px;
					line-height: 24px;
				}
				@media #{$sm} {
					padding-right: 50px;
					font-size: 40px;
					line-height: 44px;
				}
			}
			& p{
				color: #cfcece;
				font-size: 18px;
				padding-right: 80px;
				@media #{$lg} {
					padding-right: 20px;
				}
				@media #{$xs} {
					padding-right: 0px;
					font-size: 15px;
				}
				@media #{$sm} {
					padding-right: 35px;
				}

			}
			&.small{
				padding-left: 30px;
				padding-bottom: 24px;
				@media #{$md} {
					padding-right: 0;
				}
				& .title{
					padding-right: 20px;
				}
			}
		}
		& i{
			position: absolute;
			top: 20px;
			right: 20px;
			height: 45px;
			width: 45px;
			text-align: center;
			line-height: 45px;
			background: #000;
			color: $white;
			border-radius: 50%;
			cursor: pointer;
			font-size: 20px;
			@include transition(0.3s);
			&:hover{
				background: $theme-color;
				color: $white;
			}
		}

	}
	&:hover{
		& img{
			@include transform(scale(1.05));
		}
	}
}




.populer-trending{
	padding: 31px 40px 75px;
	position: relative;
	z-index: 10;
	@media #{$lg} {
		padding: 20px;
	}
	@media #{$md} {
		padding: 20px;
	}
	@media #{$xs} {
		padding: 20px;
	}
	@media #{$sm} {
		padding: 31px 40px 75px;
	}
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-image: url(../images/trending-pettern.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}
	& .trending-title{
		& .title{
			font-size: 32px;
			color: $white;
			text-transform: uppercase;
			border-bottom: 1px solid #3f3f3f;
			padding-bottom: 5px;
			margin-bottom: 30px;
			& i{
				font-size: 26px;
				color: $theme-color;
				padding-right: 6px;
			}
		}
	}
	& .populer-trending-item{
		& .trending-thumb{
			width: 180px;
			& img{
				width: 100%;
			}
		}
		& .trending-content{
			& span{
				font-size: 18px;
				font-weight: 500;
				color: $theme-color;
				text-transform: uppercase;
			}
			& .title{
				font-size: 22px;
				font-weight: 500;
				color: $white;
				line-height: 24px;
				@media #{$lg} {
					font-size: 17px;
				}
				@media #{$xs} {
					font-size: 16px;
				}
				@media #{$sm} {
					font-size: 22px;
				}

			}
		}
	}

}



.wekkly-popular-area{
	padding-top: 71px;
	padding-bottom: 80px;
	& .populer-item{
		&.item{
			& .populer-overlay{
				& .populer-content{
					@media #{$md} {
						padding-right: 80px;
					}
					& .title{
						font-size: 40px;
						line-height: 45px;
						padding-right: 90px;
						@media #{$lg} {
							padding-right: 50px;
						}
						@media #{$md} {
							padding-right: 0;
							font-size: 30px;
						}
						@media #{$xs} {
							font-size: 24px;
							line-height: 26px;
							padding-right: 30px;
						}
					}
				}
			}

		}
	}
}