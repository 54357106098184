


/*===========================
    6.VIDEO css 
===========================*/




.video-area{
	padding-top: 71px;
	position: relative;
	padding-bottom: 80px;
	margin-bottom: 71px;
	@media #{$md} {
		padding-bottom: 20px;
	}
	@media #{$xs} {
		padding-bottom: 0px;
	}
	@media #{$sm} {
		padding-bottom: 80px;
	}

	& .row{
		& .col-lg-3{
			padding-left: 7px;
			padding-right: 7px;
		}
	}

	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: #000;
		opacity: .9;

	}
	& .section-title{
		margin-bottom: 95px;			
		@media #{$xs} {
				margin-bottom: 10px;
		}			
		@media #{$sm} {
				margin-bottom: 55px;
		}
		& .title{
			color: $white;
			border-bottom-color: #1f1f1f;

		}
	}
	& .video-items{
		& img{
			width: 100%;
			opacity: .6;

		}
	}
	& .video-area-item{
		margin-top: -260px;
		@media #{$md} {
			margin-top: -210px;
		}
		@media #{$xs} {
			margin-top: 15px;
		}
		@media #{$sm} {
			margin-top: -180px;
		}

		& .video-item{	
			text-align: center;
			position: relative;
			z-index: 10;
			height: 340px;
			width: 590px;
			@media #{$md} {
				width: 480px;
			}
			@media #{$xs} {
				width: 300px;
			}
			@media #{$sm} {
				width: 400px;
			}
			& img{
				display: inline-block;
				width: 590px;
				position: relative;
				z-index: 2;
			
			}
			& .slick-arrow{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				z-index: 5;
				color: $white;
				font-size: 40px;
				@media #{$md} {
					font-size: 24px;
				}
				&.prev{
					left: -120px;
					@media #{$md} {
						left: -60px;
					}
				}
				&.next{
					right: -120px;
					@media #{$md} {
						right: -60px;
					}
				}
			}
			& .item{
				position: relative;
				z-index: 10;
				text-align: center;
				&::before{
					position: absolute;
					content: '';
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					background: #000;
					opacity: .5;
					z-index: 6;
				}
				& a{
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					z-index: 5;
					height: 90px;
					width: 90px;
					text-align: center;
					line-height: 90px;
					border-radius: 50%;
					background: $theme-color;
					color: $white;
					z-index: 10;
					@media #{$xs} {
						height: 40px;
						width: 40px;
						line-height: 40px;
						top: 40%;
					}
					@media #{$sm} {
						height: 60px;
						width: 60px;
						line-height: 60px;
						top: 50%;
					}
					&::before{
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
						opacity: .6;
						border-radius: 50%;
						background: $theme-color;
						z-index: -1;
						animation: video-pulse linear infinite 1.2s;
					}
					&::after{
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
						opacity: .6;
						border-radius: 50%;
						background: $theme-color;
						z-index: -1;
						animation: video-pulse linear infinite 1.5s;
					}

				}
				& .title{
					position: absolute;
					left: 50%;
					bottom: 20px;
					transform: translateX(-50%);
					z-index: 10;
					color: $white;
					font-weight: 600;
					font-size: 24px;
					line-height: 26px;			
					@media #{$xs} {
						font-size: 16px;
						line-height: 20px;
					}
					@media #{$sm} {
						font-size: 18px;
						line-height: 20px;
					}

				}
			}
		}
	}
}

@-webkit-keyframes video-pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes video-pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.3);
        opacity: 0;
    }
}








.video-2-area{
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	&::before{
		position: absolute;
		content: '';
		right: 0;
		top: 0;
		height: 100%;
		width: 50%;
		background: #0f0f0f;
		@media #{$md} {
			width: 100%;
		}
		@media #{$xs} {
			width: 100%;
		}
	}
	& .video-overlay{
		position: relative;
		z-index: 10;
		height: 590px;
		display: flex;
		align-items: center;
		& .icon{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			@media #{$md} {
				left: 80%;
			}
			@media #{$xs} {
				display: none;
			}
			& i{
				height: 120px;
				width: 120px;
				text-align: center;
				line-height: 120px;
				background: $white;
				border-radius: 50%;
				color: $theme-color;
				font-size: 45px;	
				position: relative;	
				&::after{
					position: absolute;
					content: '';
					left: 0px;
					top: 0;
					height: 100%;
					width: 100%;
					background: #fff;
					opacity: 1;
					border-radius: 50%;
					z-index: -1;
					animation: video-pulse linear infinite 1.5s;
				}
			}
		}
		& .video-content{
			@media #{$md} {
				margin-right: 150px;
			}
			& .title{
				color: $white;
				font-weight: 600;
				font-size: 45px;
				line-height: 50px;
				@media #{$lg} {
					font-size: 42px;
					line-height: 44px;
				}
				@media #{$xs} {
					font-size: 25px;
					line-height: 28px;
				}
				@media #{$sm} {
					font-size: 45px;
					line-height: 50px;
				}
			}
			& p{
				color: #cfcece;
				font-size: 18px;
				padding-right: 70px;
				padding-top: 15px;
				padding-bottom: 76px;
				@media #{$lg} {
					font-size: 17px;
					padding-right: 0;
				}
				@media #{$xs} {
					padding-right: 0;
					font-size: 17px;
				}
			}
			& ul{
				position: relative;
				&::before{
					position: absolute;
					content: '';
					left: 0;
					top: -14px;
					height: 1px;
					width: 140px;
					background: #999;
				}
				& li{
					font-size: 18px;
					line-height: 26px;
					font-weight: 500;
					text-transform: uppercase;
					color: $theme-color;
					&:last-child{
						color: #dfdfdf;
					}
				}
			}
		}
	}
	& .video-slide{
		& .slick-arrow{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 50px;
			height: 76px;
			background: $white;
			color: #999;
			text-align: center;
			line-height: 76px;
			cursor: pointer;
			z-index: 9;
			@include transition(0.3s);
			&:hover{
				background: $theme-color;
				color: $white;
			}
			&.prev{
				right: 55px;
			}
			&.next{
			}
		}
	}
}