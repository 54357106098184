/*-----------------------------------------------------------------------------------

    Template Name: Snowdrop 
    Template URI: http://maxsify.com/
    Description: Personal Blog HTML5 Template
    Author: maxsifythemes
    Author URI: https://themeforest.net/user/maxsifythemes
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

1.COMMON css 
2.HEADER css 
3.AUTHOR NEWS css
4.HIGHLIGHTS css
5.FEATURED NEWS css
6.VIDEO css 
7.MORE NEWS css
8.FOOTER css 
9.BANNER css 
10.NEWS css 
11.TRENDING NEWS css
12.INSTAGRAM css
13.POPULER css
14.TEAM css
15.BLOG css
16.CONTACT US css
17.SEARCH css
18.TESTIMONIALS css
19.ARTICLE POST css

-----------------------------------------------------------------------------------*/

$breakpoints: (
	'xs-phone': 320px,
	'phone': 576px,
	'tablet': 768px,
	'desktop': 992px,
	'widescreen': 1200px
);

// keywords
$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'include-media';
@import 'variables';
@import 'mixin';
@import 'common';
@import 'header';
@import 'author-news';
@import 'highlights';
@import 'featured-news';
@import 'video';
@import 'more-news';
@import 'footer';
@import 'banner';
@import 'news';
@import 'trending-news';
@import 'instagram';
@import 'populer';
@import 'team';
@import 'blog';
@import 'contact-us';
@import 'search-area';
@import 'testimonials';
@import 'article-post';
