


/*===========================
    4.HIGHLIGHTS css 
===========================*/




.highlights-area{
	padding-top: 71px;
	padding-bottom: 80px;
	margin-bottom: 71px;
	& .highlights-item{
		position: relative;
		overflow: hidden;
		&:hover{
			& img{
				transform: scale(1.1);
			}
		}
		& img{
			width: 100%;
			@include transition(0.3s);
		}
		& .highlights-content{
			position: absolute;
			left: 16px;
			bottom: 16px;
			z-index: 10;

			& i{
				height: 34px;
				width: 34px;
				text-align: center;
				line-height: 34px;
				background: $theme-color;
				color: $white;
				border-radius: 50%;
				font-size: 13px;
				z-index: -1;
				position: relative;
			}
			& span{
				background: #000;
				color: $white;
				padding: 0 15px 2px;
				border-radius: 20px;
				text-transform: uppercase;
				margin-left: -15px;
				z-index: -2;
				position: relative;
			}
		}
	}
	& .highlights-title{
		& .title{
			font-size: 24px;
			font-weight: 600;
			line-height: 26px;
			color: $black;
			padding-top: 10px;
		}
	}
	& .highlights-slide{
		& .slick-arrow{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 52px;
			height: 76px;
			text-align: center;
			line-height: 76px;
			background: $white;
			color: $black;
			cursor: pointer;
			z-index: 9;
			box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
			@include transition(0.3s);
			&:hover{
				background: $theme-color;
				color: $white;
			}
			&.prev{
				left: -66px;
			}
			&.next{
				right: -66px;
			}
		}
	}
}





