/*===========================
    17.SEARCH css 
===========================*/


.search-area{
	margin-top: -74px;
	position: relative;
	z-index: 1;
	.search-inner{
		box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
		padding: 29px 15px 41px;
		@include media('>=desktop') {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			flex-flow: row wrap;
			padding: 56px 100px 42px;
		}
	}
	.social-networks{
		@include font(16px,20px);
		display: flex;
		justify-content: center;
		flex-flow:row wrap;
		margin: 0 -5px 0;
		@include media('>=phone') {
			margin: 0 -10px 0;
		}
		@include media('>=desktop') {
			margin: 0 -10px 14px;
			justify-content: space-between;
		}
		@include media('>=widescreen') {
			@include font(20px,25px);
			letter-spacing: 0.42px;
		}
		li{
			padding: 0 5px 10px;
			@include media('>=phone') {
				padding: 0 10px;
			}
		}
		a{
			color: $dark-grey-2;
			display: flex;
			align-items: center;
			&:hover{
				color: $theme-orange;
			}
		}
		i{
			@include font(20px,24px);
			margin-right: 8px;
			@include media('>=widescreen') {
				@include font(23px,25px);
				margin-right: 12px;
			}
		}
	}
}

.search-form{
	@include media('>=desktop') {
		width: 100%;
		margin-bottom: 0;
	}
	label{
		color: $theme-orange;
	}
	.form-group{
		margin-bottom: 0;
		position: relative;
	}
	.form-control{
		@include font(16px,20px);
		border:none;
		background: $dark-grey;
		border-radius: 0;
		height: 50px;
		padding-left: 10px;
		padding-right: 129px;
		@include media('>=tablet') {
			padding-left: 70px;
			padding-right: 205px;
		}
		@include placeholder {
			color: $light-grey;
		}
	}
	button{
		position: absolute;
		top: 0;
		&.btn-search{
			display: none;
			@include media('>=tablet') {
				display: block;
			}
			left: 0;
		}
		&.btn-orange{
			right: 0;
		}
	}
	.btn-search{
		@include size(50px);
		border:none;
		background: $theme-orange;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		&:hover{
			background: lighten($theme-orange,10%);
		}
	}
}