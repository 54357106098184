



/*===========================
    16.CONTACT US css 
===========================*/




.contact-us-area{
	margin-bottom: 100px;
	& .contact-us-bg{
		background: #ea7026;
		padding-top: 50px;
		@media #{$md} {
			padding-left: 30px;
			padding-right: 30px;
		}
		@media #{$xs} {
			padding-left: 15px;
			padding-right: 15px;
		}
		@media #{$sm} {
			padding-left: 30px;
			padding-right: 30px;
		}
		& .contact-us-info{
			& .contact-us-info-item{
				margin-bottom: 38px;
				padding-left: 95px;
				position: relative;
				@media #{$lg} {
					padding-left: 60px;
				}
				@media #{$xs} {
					padding-left: 70px;
				}
				@media #{$sm} {
					padding-left: 95px;
				}
				& i{
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					height: 75px;
					width: 75px;
					text-align: center;
					line-height: 75px;
					border-radius: 50%;
					color: $theme-color;
					background: #000;
					font-size: 30px;
					@media #{$lg} {
						height: 55px;
						width: 55px;
						line-height: 55px;
						font-size: 22px;
					}
					@media #{$xs} {
						height: 60px;
						width: 60px;
						line-height: 60px;
						font-size: 24px;
					}
					@media #{$sm} {
						height: 75px;
						width: 75px;
						line-height: 75px;
						font-size: 30px;
					}
					
				}
				& .title{
					color: $white;
					text-transform: uppercase;
					font-size: 26px;
					font-weight: 700;
					padding-bottom: 9px;
				}
				& ul{
					& li{
						line-height: 24px;
						font-family: $rob;
						color: $white;
						@media #{$lg} {
							font-size: 14px;
						}
						@media #{$xs} {
							font-size: 15px;
						}
					}
				}
			}
		}
		& .contact-map{
			margin-bottom: -78px;
			& iframe{
				width: 100%;
				height: 450px;
			}
		}
	}
}




.contact-input{
	& .section-title{
		margin-bottom: 20px;
	}
	& form{
		& .input-box{
			& input{
				width: 100%;
				border: 0;
				background: #f9f9f9;
				padding-left: 35px;
				line-height: 70px;
				color: #999;
				&::placeholder{
					opacity: 1;
				}
			}
			& textarea{
				width: 100%;
				height: 230px;
				border: 0;
				background: #f9f9f9;
				padding-left: 35px;
				padding-top: 20px;
				resize: none;
				color: #999;
				&::placeholder{
					opacity: 1;
				}
			}
			& a{
				background: $theme-color;
				color: $white;
				letter-spacing: 1px;
				border-color: $theme-color;
			}
		}
	}
}

