


/*===========================
    10.NEWS css 
===========================*/




.news-area{
	&.news-4-area{
		& .news-item {
			& .news-item-thumb{
				&::before{
					background: #0e0e0e;
				}
			}
			& .news-item-content{
				& .title{
					color: $white;
				}
			}
		}
		& .news-sidebar {
			border-color: #1a1a1a;
			& .news-update-item {
				& .news-update-content {
					& .item {
						border-bottom-color: #262626;
						& .title{
							color: $white;
						}
						& span{
							color: #dbdbdb;
							opacity: .1;
						}
					}
				}
			}
			& .news-sidebar-categories{
				border-top-color: #262626;
				& .title{
					color: $white;
				}
				& .item {
					border-bottom-color: #262626;
					& span{
						color: $white;
					}
				}
			}
			& .news-sidebar-video{
				border-top-color: #262626;
				& .title{
					color: $white;
				}
				& .news-sidebar-video-item{
					& .news-sidebar-video-content{
						& .title{
							color: $white;
						}
					}
				}
			}
			& .news-sidebar-subscribe{
				border-top-color: #262626;
				& .title{
					color: $white;
				}
			}
		}
	}
	

}


.news-item{
	@media #{$md} {
		margin-top: 50px;
	}
	@media #{$xs} {
		margin-top: 50px;
	}
	& .news-item-thumb{
		position: relative;
		overflow: hidden;
		z-index: 10;
		min-height: 180px;
		margin-bottom: 12px;
		background: #F6F6F6;
		border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
		& img{
			//width: 100%;
			@include transition(0.3s);
			max-width: 100%;
			max-height: 350px;
			max-height: 180px;
			display: block;
			margin: auto;
			min-height: 180px;
			position: relative;
			z-index: 2;
			object-fit: cover;
		}
		&::before{
			position: absolute;
			content: '';
			left: -20px;
			bottom: 0;
			height: 40px;
			width: 210px;
			transform: skewX(40deg);
			background: $white;
			z-index: 3;
		}
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: url("../theme-src/images/news-def.png") no-repeat 50% 50% / contain;
		}
		& .share-item{
			position: absolute;
			right: 20px;
			top: 20px;
			& span{
				height: 50px;
				width: 50px;
				text-align: center;
				line-height: 47px;
				border: 2px solid #fff;
				border-radius: 50%;
				color: $white;
				font-size: 18px;
				cursor: pointer;
				
			}
			& ul{
				position: absolute;
				left: -100px;
				top: 4px;
				width: 100%;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				opacity: 0;
				@include transition(0.3s);
				& li{
					display: inline-block;
					& a{
						background: #eb2e32;
						border-color: #eb2e32;
						color: $white;
						height: 40px;
						width: 40px;
						line-height: 37px;
						text-align: center;
						border-radius: 50%;
					}
					&:nth-child(2){
						& a{
							background: #4cc7f3;
							border-color: #4cc7f3;
						}
					}
					&:nth-child(3){
						& a{
							background: #3b5ec6;
							border-color: #3b5ec6;
						}
					}
				}
			}
			&:hover{
				& ul{
					opacity: 1;
					left: -122px;
				}
			}
		}
	}
	& .news-item-content{
			margin-top: -6px;
		& > span{
			font-size: 20px;
			font-weight: 600;
			color: $theme-color;
			text-transform: uppercase;
			position: relative;
            
			&::before{
				position: absolute;
				content: '';
				right: -24px;
				bottom: 7px;
				height: 1px;
				width: 18px;
				background: $theme-color;
			}
		}
		& .title{
			font-size: 22px;
			color: $black;
			line-height: 32px;
			font-weight: 600;
			padding-right: 25px;
			padding-top: 5px;
			padding-bottom: 16px;
			max-height: 64px;
			//overflow: hidden;
			text-overflow: ellipsis;
			overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
			margin-bottom: 12px;
			@include transition(0.3s);
			@media #{$lg} {
				font-size: 26px;
				padding-right: 0;
			}
			@media #{$md} {
				padding-right: 0;
			}
			@media #{$xs} {
				font-size: 24px;
				line-height: 30px;
				padding-right: 14px;
			}
			@media #{$sm} {
				font-size: 22px;
				line-height: 24px;
				padding-right: 0;
			}
			&:hover{
				color: $theme-color;
			}
		}
		& .author-item-sub{
			display: flex;
			align-items: center;
			& ul{
				& li{
					display: inline-block;
					color: #999999;
					font-size: 18px;
					font-weight: 500;
					margin-right: 18px;
					& i{
						font-size: 16px;
					}
				}
			}
			& span{
				background: #020f50;
				line-height: 27px;
				padding: 0 16px;
				border-radius: 20px;
				color: $white;
				font-size: 18px;
				font-weight: 500;
				margin-left: 10px;
				

				a {
					color: inherit;
				}
				& i{
					font-size: 16px;

				}
			}
		}
		>a {
		  display: block;
		  overflow: hidden;
		}
	}
	&:hover{
		& .news-item-thumb{
			& img{
				transform: scale(1.06);
			}
		}
	}
	&.full-item{
		& .news-item-content{
			& .title{
				font-size: 40px;
				padding-top: 12px;
				padding-bottom: 64px;
				@media #{$lg} {
					font-size: 36px;
				}
				@media #{$xs} {
					font-size: 30px;
					line-height: 32px;
				}
			}
			& p{
				font-size: 18px;
				color: #666;
				padding-right: 30px;
				padding-bottom: 22px;
				@media #{$xs} {
					padding-right: 0;
					font-size: 16px;
				}
				@media #{$sm} {
					padding-right: 0;
					font-size: 18px;
				}

			}
		}
	}
}





.news-sidebar{
	border: 3px solid #f6f6f6;
	& .news-update-btn{
		ul{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			& li{
				width: 50%;
				width: 100%;
				& a{
					border-radius: 6px;
					margin: 2px;
					background: #f6f6f6;
					//border-radius: 0;
					font-size: 18px;
					font-weight: 700;
					line-height: 16px;
					width: calc(100% - 4px);
					display: inline-block;
					& span{
						line-height: 16px;
						text-transform: uppercase;
						letter-spacing: 2px;
						padding: 12px 0;
						position: relative;
						padding-left: 26px;
						color: $black;
						margin-left: 12px;
						@media #{$lg} {
							margin-right: 0;
							font-size: 15px;
						}
						@media #{$xs} {
							margin-left: 0;
							font-size: 15px;
						}
						@media #{$sm} {
							margin-left: 12px;
							font-size: 16px;

						}
						& i{
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							font-size: 20px;
							color: #999;
						}
					}
					&.active{
						background: $theme-color;


						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ea7026+0,ea7026+0,e8a076+44,c9c9c9+100 */
background: #020f50; /* Old browsers */




						& span{
							color: $white;
							& i{
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}


.news-update-item{
	padding: 40px 40px 10px 40px;
	@media #{$lg} {
		padding: 30px 30px 10px 30px;
	}
	@media #{$xs} {
		padding: 20px;
	}
	& .news-update-thumb{
		& img{
			width: 100%;
		}
	}
	& .news-update-content{
		& .item{
			height: 90px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			border-bottom: 3px solid #f6f6f6;
			position: relative;
			& .title{
				font-size: 14px;
				line-height: 16px;
				color: $black;
				font-weight: 600;
				padding-left: 52px;
				padding-left: 0;
				padding-right: 10px;
				padding-right: 0;
				//@media #{$lg} {
					//font-size: 20px;
					//padding-left: 30px;
				//}
				//@media #{$xs} {
					//padding-right: 0px;
					//font-size: 22px;
					//padding-left: 32px;
				//}
				//@media #{$sm} {
					//padding-right: 90px;
					//font-size: 24px;
					//padding-left: 52px;
				//}
			}
			date {
				display: block;
				margin-top: 4px;
				font-weight: normal;
				color: $body-text;
			}
			& span{
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 50px;
				font-weight: 300;
				color: #dbdbdb;
			}
			&:last-child{
				border-bottom: 0;
			}
		}
	}

}


.news-sidebar-categories{
	padding: 33px 40px 36px;
	border-top: 3px solid #f6f6f6;
	& .title{
		font-size: 26px;
		font-weight: 700;
		text-transform: uppercase;
		color: $black;
		letter-spacing: 1px;
		position: relative;
		margin-bottom: 5px;
		&::before{
			position: absolute;
			content: '';
			left: 0;
			bottom: -5px;
			height: 3px;
			width: 45px;
			background: $theme-color;
		}
	}
	& .item{
		border-bottom: 3px solid #f6f6f6;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
		padding-bottom: 3px;
		padding-top: 18px;
		position: relative;
		&::before{
			position: absolute;
			content: '';
			right: 0;
			bottom: -3px;
			height: 3px;
			width: 12px;
			background: $theme-color;
		}
		& span{
			font-size: 18px;
			font-weight: 600;
			color: #454545;
			text-transform: uppercase;
			&.color{
				color: $theme-color;
			}
		}
	}

}


.news-sidebar-video{
	padding: 33px 40px 40px;
	border-top: 3px solid #f6f6f6;
	& .video-title{
		& .title{
			font-size: 26px;
			font-weight: 700;
			text-transform: uppercase;
			color: $black;
			letter-spacing: 1px;
			position: relative;
			margin-bottom: 34px;
			&::before{
				position: absolute;
				content: '';
				left: 0;
				bottom: -5px;
				height: 3px;
				width: 45px;
				background: $theme-color;
			}
		}
	}
	& .news-sidebar-video-item{
		margin-top: 23px;
		& .news-sidebar-video-thumb{
			position: relative;
			& img{
				width: 100%;
			}
			& a{
				position: absolute;
				right: 10px;
				top: 10px;
				height: 50px;
				width: 50px;
				text-align: center;
				line-height: 50px;
				border-radius: 50%;
				background: $white;
				color: $black;
				font-size: 14px;
			}
		}
		& .news-sidebar-video-content{
			& .title{
				font-size: 24px;
				font-weight: 600;
				line-height: 26px;
				color: $black;
				padding-top: 16px;
				@media #{$xs} {
					font-size: 22px;
				}
				@media #{$sm} {
					font-size: 24px;
				}
			}
			& span{
				font-size: 18px;
				color: #999;
				font-weight: 500;
				text-transform: uppercase;
			}
		}
	}
	& .news-video-btn{
		margin-top: 23px;
		& a{
			padding: 0 45px;
			font-weight: 700;
			letter-spacing: 1px;
			@media #{$xs} {
				padding: 0 20px;
			}
		}
	}
}





.news-sidebar-subscribe{
	padding: 33px 40px 40px;
	border-top: 3px solid #f6f6f6;
	& .subscribe-title{
		& .title{
			font-size: 26px;
			font-weight: 700;
			text-transform: uppercase;
			color: $black;
			letter-spacing: 1px;
			position: relative;
			margin-bottom: 29px;
			&::before{
				position: absolute;
				content: '';
				left: 0;
				bottom: -5px;
				height: 3px;
				width: 45px;
				background: $theme-color;
			}
		}
	}
	& .news-sidebar-subscribe-content{
		& p{
			font-size: 16px;
			line-height: 24px;
			color: #666;

		}
		& .input-box{
			margin-top: 15px;
			& input{
				width: 100%;
				text-align: center;
				border: 0;
				background: #f6f6f6;
				line-height: 65px;
				font-size: 15px;
				font-weight: 300;
				&::placeholder{
					opacity: 1;
					color: #666;
				}
			}
			& button{
				width: 100%;
				background: $theme-color;
				color: $white;
				border-color: $theme-color;
				margin-top: 10px;
				font-size: 18px;
				font-weight: 700;
				letter-spacing: 1px;
				border-radius: 6px;
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ea7026+0,ea7026+0,e8a076+44,ea7026+100 */
background: #020f50; /* Old browsers */

			}
		}
	}
}







.news-3-area{
	& .news-thumb{
		position: relative;
		& img{
			width: 100%;
		}
		& i{
			position: absolute;
			top: 20px;
			right: 20px;
			height: 45px;
			width: 45px;
			text-align: center;
			line-height: 45px;
			background: #000;
			color: $white;
			border-radius: 50%;
			cursor: pointer;
			font-size: 20px;
			@include transition(0.3s);
			&:hover{
				background: $theme-color;
				color: $white;
			}
		}
	}
	& .news-content{
		@media #{$md} {
			margin-left: 0;
		}
		@media #{$xs} {
			margin-left: 0;
		}
		& .author-item-sub{
			display: flex;
			align-items: center;
			@media #{$xs} {
				margin-top: 20px;
			}
			& ul{
				& li{
					display: inline-block;
					color: #999999;
					font-size: 18px;
					font-weight: 500;
					margin-right: 18px;
					& i{
						font-size: 16px;
					}
				}
			}
			& span{
				background: #e71919;
				line-height: 27px;
				padding: 0 16px;
				border-radius: 20px;
				color: $white;
				font-size: 18px;
				font-weight: 500;
				margin-left: 10px;
				& i{
					font-size: 16px;

				}
			}
		}
		& .news-item{
			@media #{$md} {
				margin-top: 0px;
			}
			@media #{$xs} {
				margin-top: 0px;
			}
			& .title{
				font-size: 32px;
				line-height: 36px;
				font-weight: 600;
				color: $black;
				padding-top: 16px;
				padding-bottom: 18px;
				padding-right: 40px;
				@media #{$lg} {
					padding-right: 0;
					font-size: 30px;
					line-height: 32px;
				}
				@media #{$xs} {
					padding-right: 0;
					font-size: 24px;
					line-height: 26px;
				}
			}
			& p{
				font-size: 18px;
				padding-right: 40px;
				color: #666;
				padding-bottom: 20px;
				@media #{$lg} {
					padding-right: 0;
					font-size: 17px;
					line-height: 24px;
					padding-bottom: 10px;
				}
				@media #{$xs} {
					padding-right: 0;
					font-size: 17px;
				}
			}
		}
		& .news-info{
			& img{
				border-radius: 50%;
			}
			& span{
				font-size: 18px;
				font-weight: 500;
				color: $white;
				background: $theme-color;
				border-radius: 20px;
				line-height: 28px;
				padding: 0 17px;
				margin-left: -15px;
				text-transform: uppercase;
				@media #{$xs} {
					font-size: 14px;
				}
			}
			& p{
				font-size: 18px;
				color: #999;
				text-transform: uppercase;
				padding-left: 10px;
				@media #{$xs} {
					font-size: 14px;
				}
			}
		}
	}
}
.page-links {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	justify-content: center;
	.main-btn {
		display: block;
		line-height: 32px;
		padding: 0 12px;
		margin-bottom: 8px;
	}
}