


/*===========================
    8.FOOTER css 
===========================*/




.footer-area{
	position: relative;
	padding-top: 44px;
	z-index: 10;
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: #020f50;
		z-index: -1;
		
		
	}
	& .footer-item-1{
		& .footer-content{
			& p{
				font-size: 17px;
				color: #999;
				font-weight: 300;
				padding-right: 50px;
				padding-top: 26px;
				padding-bottom: 28px;
				@media #{$lg} {
					padding-right: 0;
					font-size: 16px;
				}
				@media #{$md} {
					padding-right: 0;
				}
				@media #{$xs} {
					padding-right: 0;
				}
			}
			& ul{
				& li{
					font-size: 17px;
					color: #999;
					line-height: 30px;
					padding-right: 110px;
					@media #{$lg} {
						padding-right: 0;
					}
					@media #{$md} {
						padding-right: 70px;
					}
					@media #{$xs} {
						padding-right: 0px;
					}
					& span{
						color: $theme-color;
					}
				}
			}
		}
	}
	& .footer-item-2{
		& .footer-title{
			& .title{
				padding-bottom: 5px;
			}
		}
		& .footer-author-item{
			& .item{
				position: relative;
				//padding-left: 120px;
				& img{
					position: absolute;
					left: 0;
					top: 0;
					max-width: 30%;
				}
				& .title{
					color: $white;
					a { color: inherit; }
					font-size: 18px;
					line-height: 22px;
					font-weight: 500;
					padding-bottom: 11px;
					padding-right: 30px;
					@media #{$lg} {
						padding-right: 0;
						font-size: 20px;
					}
					@media #{$md} {
						padding-right: 0;
					}
					@media #{$xs} {
						padding-right: 0;
						font-size: 19px;
						line-height: 22px;
					}
					@media #{$sm} {
						padding-right: 0;
						font-size: 24px;
						line-height: 26px;
					}
				}
				& span{
					font-size: 16px;
					font-weight: 500;
					color: #d4d4d4;
					text-transform: uppercase;
					& span{
						background: #e71919;
						color: $white;
						border-radius: 20px;
						padding: 0 16px;
						margin-left: 20px;
						@media #{$xs} {
							margin-left: 10px;
						}
						@media #{$sm} {
							margin-left: 20px;
						}
					}
				}
			}
		}
	}
	& .footer-item-3{
		& .footer-title{
			padding-bottom: 22px;
		}
		& .footer-links{
			& ul{
				& li{
					& a{
						font-size: 18px;
						line-height: 40px;
						color: #999;
						font-family: $rob;
						@include transition(0.3s);
						& i{
							padding-right: 4px;
						}
						&:hover{
							color: $theme-color;
						}
					}
				}
				&.ml-55{
					margin-left: 25px;
					@media #{$xs} {
						margin-left: 18px;
					}
					@media #{$sm} {
						margin-left: 25px;
					}
				}
			}
		}
	}


}

.footer-title{
	& .title{
		color: $white;
		text-transform: uppercase;
		font-size: 22px;
	}
}




.footer-newsletter{
	background: #000;
	border-top: 1px solid rgba(222, 227, 225,.1);
	border-bottom: 1px solid rgba(222, 227, 225,.1);
	margin-top: 73px;
	& .newsletter-flex{
		height: 130px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media #{$md} {
			height: auto;
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@media #{$xs} {
			height: auto;
			padding-top: 30px;
			padding-bottom: 30px;
		}
		& .newsletter-title{
			@media #{$md} {
				text-align: center;
			}
			@media #{$xs} {
				text-align: center;
			}
			& .title{
				color: $white;
				text-transform: uppercase;
				font-size: 22px;
				& i{
					color: $theme-color;
					padding-right: 5px;
				}
			}
		}
		& .newsletter-input{
			position: relative;
			width: 570px;
			@media #{$lg} {
				width: 365px;
			}
			@media #{$md} {
				text-align: center;
				width: auto;
				margin-top: 30px;
				margin-bottom: 30px;
			}
			@media #{$xs} {
				text-align: center;
				width: auto;
				margin-top: 30px;
				margin-bottom: 30px;
			}
			& input{
				width: 100%;
				line-height: 70px;
				background-color: transparent;
				border: 2px solid #878787;
				color: #828282;
				padding-left: 30px;
				font-size: 17px;
				&::placeholder{
					opacity: 1;
				}
			}
			& button{
				position: absolute;
				right: 7px;
				top: 50%;
				transform: translateY(-50%);
				line-height: 56px;
				border: 0;
				background: $theme-color;
				color: $white;
				padding: 0 27px;
				font-weight: 600;
				font-size: 18px;
				font-family: $bar;
				text-transform: uppercase;
				@media #{$xs} {
					padding: 0 10px;
					font-size: 16px;
				}
				& i{
					padding-right: 5px;
				}

			}
		}
		& .newsletter-social{
			@media #{$md} {
				text-align: center;
			}
			@media #{$xs} {
				text-align: center;
			}
			& ul{
				& li{
					display: inline-block;
					& a{
						height: 70px;
						width: 70px;
						text-align: center;
						line-height: 70px;
						background: #5e5eb7;
						color: $white;
						margin-right: 2px;
						font-size: 24px;
						@media #{$xs} {
							height: 50px;
							width: 50px;
							line-height: 50px;
						}
					}
					&:nth-child(2){
						& a{
							background: #c72026;
						}
					}
					&:nth-child(3){
						& a{
							background: #29a4dd;
						}
					}
					&:nth-child(4){
						& a{
							background: #42548e;
						}
					}
					&:nth-child(5){
						& a{
							background: #e32a92;
						}
					}
				}
			}
		}
	}

}
.copyright-text{
	& p{
		line-height: 100px;
		font-size: 20px;
		color: #999;
		font-family: $bar;
		@media #{$xs} {
			font-size: 17px;
		}
		& span{
			font-weight: 600;
			color: $theme-color;
		}
	}
	.new-home &{
		p{
			@media #{$xs} {
				font-size: 16px !important;
			}
		}
	}
}


.new-home{
	.footer{
		@include font(18px,25px);
		background: $black-2;
		letter-spacing: 0.53px;
		color: #6f6f6f;
		hr{
			margin: 0 !important;
		}
		p{
			color: #6f6f6f;
		}
		.footer-top{
			margin-bottom: 0px;
			@include media('>=desktop') {
				margin-bottom: 133px;
			}
		}
		.container{
			padding-top: 50px;
			@include media('>=desktop') {
				padding-top: 123px;
			}
			@include media('>=1920px') {
				padding: 123px 45px 0;
			}
		}
		.footer-links{
			li{
				margin-bottom: 18px;
			}
			a{
				color: #6f6f6f;
				&:hover{
					color: $white;
				}
			}
		}
		address{
			margin-bottom: 30px;
			@include media('>=desktop') {
				margin-bottom: 69px;
			}
		}
		.title{
			@include font(25px,30px);
			margin-bottom: 22px;
			display: block;
		}
		.cards-list{
			display: flex;
			flex-flow: row wrap;
			li{
				margin-right: 10px;
				@include media('>=widescreen') {
					margin-right: 13px;
				}
				&:last-child{
					margin-right: 0;
				}
			}
			a{
				&:hover{
					opacity: 0.8;
				}
			}
			img{
				@include media('>=desktop') {
					width: 33px;
				}
				@include media('>=widescreen') {
					width: auto;
				}
			}
		}
		.subscribe-form{
			@include font(18px,22px);
			@include media('>=1400px') {
				@include font(22px,26px);
			}
			.form-group{
				position: relative;
			}
			.form-control{
				@include font(18px,22px);
				height: 40px;
				border-radius: 0;
				border:none;
				padding-left: 13px;
				padding-right: 93px;
				@include media('>=desktop') {
					height: 52px;
				}
				@include media('>=1400px') {
					@include font(22px,26px);
					padding-right: 145px;
				}
				@include placeholder {
					color: #b7b7b7;
				}
			}
			button{
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
				max-width: 80px;
				color: $black;
				padding: 5px;
				@include media('>=1400px') {
					max-width: 132px;
				}
			}
		}
		.copyright{

			text-align: center;
			padding-top: 20px;
			padding-bottom: 20px;
			@include media('>=phone') {
				display: flex;
				justify-content: space-between;
			}
			@include media('>=tablet') {
				padding-top: 41px;
				padding-bottom: 41px;
			}
			a{
				color: rgba($white, 0.71);
				&:hover{
					color: $white;
				}
			}
			p{
				@include font(14px,20px);
				color: rgba($white, 0.71);
				margin-bottom: 15px;
				@include media('>=phone') {
					margin-bottom: 0;
				}
				
			}
		}
	}
}



/*===== Go Top =====*/


.go-top-area{
    position: relative;
    z-index: 9999;
    
    & .go-top{
        position: fixed;
        cursor: pointer;
        top: 0;
        right: 24px;
        color: #ffffff;
        background-color: $theme-color;
        z-index: 9999;
        width: 40px;
        text-align: center;
        height: 42px;
        line-height: 42px;
        opacity: 0;
        visibility: hidden;
        @include transition(0.9s);
        border-right: 0;
        
        & i{
            position: absolute;
            top: 50%;
            left: -4px;
            right: 0;
            margin: 0 auto;
            font-size: 15px;
            @include transform(translateY(-50%));
            @include transition(0.5s);
            
            &:last-child{
                opacity: 0;
                visibility: hidden;
                top: 60%;
            }
        }
        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: $theme-color;
            opacity: 0;
            visibility: hidden;
            @include transition(0.5s);
        }
        
        &:focus,
        &:hover{
            color: $white;
            
            &::before{
                opacity: 1;
                visibility: visible;
            }
            
            & i{
                &:first-child{
                    opacity: 0;
                    top: 0;
                    visibility: hidden;
                }
                &:last-child{
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
                }
            }
        }
        
        &.active{
            top: 98%;
            @include transform(translateY(-98%));
            opacity: 1;
            visibility: visible;
            border-radius: 0;
            right: 20px;
            @media #{$lg} {
                top: 93%;
            }
            @media #{$md} {
                top: 93%;
            }
        }
    }
}


.go-top-wrap {
    position: relative;
    
    & .go-top-btn {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: $white;
        top: 3px;
        z-index: 1;
        background: $theme-color;
        
        & i{
            font-size: 20px;
            font-weight: 700;
            padding-left: 4px;
            color: $white;
        }
        
        &::after{
            z-index: -1;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            height: 40px;
            @include animation(ripple 1.6s ease-out infinite);
            opacity: 0;
            background: $theme-color;
        }
        
        &:hover{
            background-color: $black;
            color: $white;
        }
    }
}

@keyframes ripple {
  0%, 35% {
    @include transform(scale(0));
    opacity: 1;
  }
  50% {
    @include transform(scale(1.5));
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    @include transform(scale(2));
  }
}

@-webkit-keyframes ripple {
  0%, 35% {
    @include transform(scale(0));
    opacity: 1;
  }
  50% {
    @include transform(scale(1.5));
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    @include transform(scale(2));
  }
}
