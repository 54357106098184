// Font Family
$bar: 'Candara', sans-serif;
$rob: 'Candara', sans-serif;


// Colors 
$white: #fff;

$white-2: #f8f8f8;

$black: #333;

$black-2: #000;

$grey:#f6f6f6;

$grey-2: #d1d1d1;

$grey-3: #d4d4d4;

$dark-grey: #eee;

$dark-grey-2: #32353c;

$light-grey: #cecece;

$theme-color: #ea7026;
//$theme-color: #061252;
//$theme-color: #fdb94a;

$body-text: #666;

$hr-border-color:#eceff8;

$theme-orange: #e77225;


// Heading Color
$heading-color: #404040;

// Soft color
$black-soft:#7e7e7e;


// Responsive Variables
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';


