


/*===========================
    12.INSTAGRAM css 
===========================*/




.instagram-flex{
	@media #{$xs} {
		margin-left: 20px;
		padding-right: 20px;
	}
	@media #{$sm} {
		margin-left: 100px;
		padding-right: 100px;
	}
	& .instagram-item{
		position: relative;
		overflow: hidden;
		& img{
			width: 100%;
			@include transition(0.3s);
		}
		& .instagram-overlay{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0,.7);
			opacity: 0;
			@include transition(0.3s);
			& > i{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				color: $white;
				font-size: 55px;
				@media #{$md} {
					font-size: 30px;
				}
			}
			& span{
				position: absolute;
				bottom: 25px;
				right: 30px;
				color: $white;
				font-size: 18px;
				font-weight: 700;
				text-transform: uppercase;
				@media #{$md} {
					font-size: 16px;
				}
				& i{
					padding-right: 6px;
				}
			}

		}
		&:hover{
			& .instagram-overlay{
				opacity: 1;
			}
			& img{
				transform: scale(1.05);
			}
		}
		& .instagram-content{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: $theme-color;
			& .item{
				margin-bottom: 45px;
				@media #{$md} {
					margin-bottom: 20px;
				}
				& span{
					font-size: 24px;
					color: $white;
					@media #{$md} {
						font-size: 20px;
					}
				}
				& .title{
					color: $white;
					text-transform: uppercase;
					font-size: 50px;
					line-height: 36px;
					padding-top: 3px;
					padding-bottom: 60px;
					@media #{$lg} {
						font-size: 38px;
						padding-bottom: 30px;
						font-size: 38px;
					}
					@media #{$md} {
						padding-bottom: 20px;
						font-size: 32px;
					}
				}
				& a{
					background: #000;
					border-color: #000;
					color: $white;
					letter-spacing: .50px;
					@media #{$lg} {
						padding: 0 30px;
					}
					@media #{$md} {
						padding: 0 30px;
					}
				}
			}
		}
	}
}







.more-news-area{
	padding-top: 71px;
	padding-bottom: 80px;
	&.more-news-page{
		padding-top: 50px;
	}
	&.more-page-area{
		padding-top: 60px;
	}
	&.more-news-4-area{
		& .title{
			color: $white;
			border-bottom-color: #292929;
		}
		& .news-item{
			& .news-item-thumb{
				&::before{
					background: #0e0e0e;
				}
			}
		}
	}
}